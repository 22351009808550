import { Link } from 'react-router-dom';
import LatestNewsCard from '../../templates/LatestNewsCard';
import { useEffect, useState } from 'react';
import { apiService } from '../../services/apiService';
import { authenticationService } from '../../services/authenticationService';
import SavedDataMenu from './SavedDataMenu';

export default function UserDashboardMenu({ onError }) {
    const [playerWatchlistCount, setPlayerWatchlistCount] = useState(0);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/watchlist')
            .then((data) => {
                const players = Array.isArray(data) ? data : [];
                setPlayerWatchlistCount(players.length);

            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="p-2">
            <div className='mb-5'>
                <Link to="/" className="fw-semi-bold small text-decoration-none"><i className="fa-light fa-grid me-2"></i>My dashboard</Link>
            </div>
            <div className="mb-5">
                <p className="fw-semi-bold small text-neutral-gray-700">Your preferences</p>
                <div className="fw-semi-bold border-start border-dark-mode-200 p-2 pb-1 ">
                    <SavedDataMenu/>
                </div>
                {playerWatchlistCount > 0 && (
                    <LatestNewsCard />
                )}
            </div>
        </div>
    )
}