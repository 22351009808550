

export default function PlayerStrengthsAndWeaknesses({ props, data = [] }) {

    const strengths = data?.filter(item => item.top);
    const weaknesses = data?.filter(item => item.bottom);


    return (
        <div>
            {data && 0 < data.length &&
                <>
                    <p className="large d-md-block d-none fw-semi-bold">Player strengths</p>
                    <p className="small d-md-none d-block fw-semi-bold mb-2">Player strengths</p>

                    <div className={'d-sm-grid  grid-2-cols grid-md-4-cols grid-gap-3' + (0 < weaknesses.length ? ' mb-3' : '')}>
                        {
                            data.map((item, i) => {
                                return (
                                    <div key={i} className=" my-3 my-sm-0" >
                                        <div className="h-100 bg-dark-mode-50 py-3 px-4 rounded-2 d-flex  justify-content-between align-items-center">
                                            <div >{item.stat_name}{false && <i className='ms-2 fa-light fa-question-circle text-neutral-grey-700'></i>}</div>
                                            <div className='badge px-25 py-2 bg-crayola-red-700 fw-semi-bold'>{(item.top_or_bottom.includes('Bottom') ? 'Bottom' : 'Top')} {Math.floor(1 + (100 - item.raw_percentile))}%</div>
                                        </div>
                                    </div>
                                )

                            })
                        }
                    </div>

                    {0 < weaknesses.length &&
                        <div className={'d-sm-grid  grid-2-cols grid-md-4-cols grid-gap-3' + (0 < strengths.length ? ' mt-3' : '')}>
                            {
                                weaknesses.map((item, i) => {
                                    return (
                                        <div key={i} className=" my-3 my-sm-0">
                                            <div className="h-100 bg-dark-mode-50 py-3 px-4 rounded-2 d-flex  justify-content-between align-items-center">
                                                <div >{item.stat_name}{false && <i className='ms-2 fa-light fa-question-circle text-neutral-grey-700'></i>}</div>
                                                <div className='badge px-25 py-2 bg-fio-error-500 fw-semi-bold'>{item.top}</div>
                                            </div>
                                        </div>
                                    )

                                })
                            }
                        </div>
                    }
                </>}
        </div >
    );
}
