
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import Loader from '../../templates/Loader';
import { authenticationService } from '../../services/authenticationService';
import NewsFeedListing from "./NewsFeedListing";
import NoSearchTerms from "../../templates/NewsFeed/NoSearchTerms";
import HelperFunctions from '../../helpers/HelperFunctions';
import { Collapse, Toast, ToastContainer, Modal, Form, Row } from 'react-bootstrap';

export default function NewsFeedComponent({ props, onError }) {

    const ref = useRef(null);
    const [savedSearchTermId, setSavedSearchTermId] = useState(null);
    const [savedSearches, setSavedSearches] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [savedSearchesOpen, setSavedSearchesOpen] = useState(false);
    const [toastType, setToastType] = useState('');
    const [initiallyLoaded, setInitiallyLoaded] = useState(false);
    const [hasFollowedPlayers, setHasFollowedPlayers] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [searchName, setSearchName] = useState('');
    const [saveSearchFormValidated, setSaveSearchFormValidated] = useState(false);
    const [searchParameters, setSearchParameters] = useState(
        { 'question': '' }
    );

    const { buildQueryStringFromFilterObj } = HelperFunctions();

    const { search } = useLocation();

    useEffect(() => {
        console.log('Location changed' + search);
    }, [search]);


    const handleCloseModal = () => setShowModal(false);

    const handleOpenModal = () => {
        setSearchName('');
        setSaveSearchFormValidated(false);
        setShowModal(true)
    };

    const updateQuestion = (savedSearch) => {

        savedSearch.last_accessed = new Date().toString();

        let data = new FormData();
        data.append('id', savedSearch.id);

        apiService.apiPostForm(process.env.REACT_APP_API_URL + '/mynews/searchterms/update_search_term_last_accessed', data);

        setSearchParameters((prevParams) => ({
            ...prevParams,
            'question': savedSearch.question
        }));

        let stateString = buildQueryStringFromFilterObj({ 'question': savedSearch.question });
        window.history.replaceState({}, '', stateString);

        setSearchPhrase(savedSearch.question);
        setSavedSearchesOpen(!savedSearchesOpen);



    }

    const removeSearchTerm = (id) => {

        let data = new FormData();
        data.append('id', id);

        apiService.apiPostForm(`${process.env.REACT_APP_API_URL}/mynews/searchterms/remove`, data)
            .then(() => {
                //tidy up the interface
                if (id === savedSearchTermId)
                    setSavedSearchTermId(null);

                setSavedSearches(l => l.filter(item => item.id !== id));
                setToastType('remove');
                setShowToast(true);
            })
            .catch(error => {
                if ('401' === error.message) {
                    authenticationService.logout();
                }
                onError('Failed to update search terms. ' + error.message);
            });
    }

    const saveSearchTerm = (event) => {

        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setSaving(true);

            let data = new FormData();
            data.append('question', searchParameters.question);
            data.append('name', searchName);

            apiService.apiPostForm(`${process.env.REACT_APP_API_URL}/mynews/searchterms/save`, data)
                .then((resp) => {

                    setSavedSearchTermId(resp.id);

                    setSavedSearches(prevParams => [
                        ...prevParams,
                        { 'id': resp.id, 'question': searchParameters.question, 'name': searchName, 'last_accessed': new Date().toString(), 'created_at': new Date().toString() }
                    ]);
                    setSaving(false);
                    setSearchName('');
                    handleCloseModal();
                    setToastType('save');
                    setShowToast(true);


                })
                .catch(error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    }
                    setSavedSearchTermId(null);
                    onError('Failed to update search terms. ' + error.message);
                });
        }
        setSaveSearchFormValidated(true);
    };

    useEffect(() => {
        if (0 < window.location.search.length) {

            const params = new URLSearchParams(window.location.search);

            if (params.has('question')) {
                setSearchParameters((prevParams) => ({
                    ...prevParams,
                    'question': params.get('question')
                }));
                setSearchPhrase(params.get('question'));
            }

        }
    }, [search]);

    useEffect(() => {

        if (searchParameters.question) {
            let data = new FormData();
            data.append('question', searchParameters.question);


            apiService.apiPostForm(process.env.REACT_APP_API_URL + '/mynews/searchterms/find', data)
                .then((resp) => {
                    setSavedSearchTermId(resp.id);
                },
                    error => {
                        if ('401' === error.message) {
                            authenticationService.logout();
                        } else {
                            onError(error.message + ' error');
                        }
                    }
                );
        }
    }, [onError, searchParameters.question]);


    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/watchlist')
            .then((data) => {
                setHasFollowedPlayers(0 < data.length);
                setInitiallyLoaded(true)
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                })

        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/searchterms/list')
            .then((resp) => {

                setSavedSearches(resp.data);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                }
            );
    }, [onError]);


    useEffect(() => {
        if (searchParameters.question) {
            let searchParametersCombined = { ...searchParameters };
            let stateString = buildQueryStringFromFilterObj(searchParametersCombined);
            window.history.replaceState({}, '', stateString);
        }
    }, [searchParameters, buildQueryStringFromFilterObj]);



    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        setSavedSearchesOpen(false);
        setSearchParameters((prevParams) => ({
            ...prevParams,
            'question': searchPhrase,

        }));
    }

    return (
        <div> {!initiallyLoaded ? (
            <div className="ratio ratio-16x9">
                <div className='center-content'>
                    <Loader />
                </div>
            </div>
        ) : (<>
            {hasFollowedPlayers ?
                <>
                    <div className="mb-2 d-flex justify-content-between align-items-center">
                        <h4 className="fw-bold mb-0">Search the news</h4>
                        {searchParameters.question &&
                            <>

                                {savedSearchTermId ?
                                    <p onClick={() => removeSearchTerm(savedSearchTermId)} className="cursor-pointer mb-0 text-crayola-red-500">Saved search term
                                        <i
                                            title="Save search terms"
                                            className="fa-solid fa-bookmark ps-2"
                                        ></i>
                                    </p> :
                                    <p onClick={handleOpenModal} className="cursor-pointer mb-0 ">Save search term
                                        <i
                                            title="Save search terms"
                                            className="fa-light fa-bookmark ps-2"
                                        ></i>
                                    </p>
                                }
                            </>
                        }
                    </div>
                    <div className={`bg-dark-mode-700 py-3  mb-3 rounded-3 ${savedSearchesOpen ? 'rounded-bottom-start-0 rounded-bottom-end-0' : ''}`}>
                        <div className='px-3'>
                            <form onSubmit={handleSearchSubmit} >
                                <div className="justify-content-between align-items-center  d-flex mb-3">
                                    <div className="btn-group focus-dark  w-100  me-3  rounded-2 rounded-top-start-0 rounded-bottom-start-0">
                                        <div className="ps-2 border border-1 py-3 pe-0 border-dark-mode-50 rounded-2 rounded-top-end-0 border-end-0 rounded-bottom-end-0 bg-dark-mode-50 text-decoration-none  small position-relative center-content"><i className="fa-light fa-search"></i></div>
                                        <input type="text" className={'py-25 px-2 text-white bg-dark-mode-50 border-1 border-start-0 border-end-0 border-dark-mode-50 form-control form-control-lg ' + (false && searchParameters.question ? 'rounded-0' : 'rounded-top-start-0 rounded-bottom-start-0 rounded-2 pe-5')} placeholder="Search the news.." required value={searchPhrase} onChange={(e) => setSearchPhrase(e.target.value)} />
                                        {false && searchParameters.question &&
                                            <>
                                                {savedSearchTermId ?
                                                    <button type="button" onClick={() => removeSearchTerm(savedSearchTermId)} className=" text-crayola-red-500 btn btn-link border-1 border-dark-mode-50 rounded-2 rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"><i className="fa-solid fa-star"></i></button>
                                                    :
                                                    <button type="button" onClick={saveSearchTerm} className=" btn btn-link border-1 border-dark-mode-50 rounded-2 rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"><i className="fa-light fa-star text-white"></i></button>
                                                }

                                            </>
                                        }
                                    </div>
                                    <button type="submit" className="btn btn-lg bg-crayola-red-500 text-decoration-none small fw-semi-bold  rounded-2 flex-0">Search</button>
                                </div>

                                <div className="btn-group focus-dark active w-100 mt-5 d-none">
                                    <input type="text" className="py-25 px-2 text-white bg-dark-mode-50 border-1 border-start-0 border-end-0 border-neutral-gray-900 rounded-pill form-control form-control-lg  rounded-top-end-0 rounded-bottom-end-0" placeholder="Search the news.." required value={searchPhrase} onChange={(e) => setSearchPhrase(e.target.value)} />
                                    <button type="submit" className="btn btn-link border-1 border-neutral-gray-900 rounded-pill rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"><i className="fa-solid fa-magnifying-glass text-white"></i></button>
                                </div>
                            </form>
                            <p className='text-neutral-gray-700 small text-center'>Look for players or keywords across all sources.</p>
                            <span
                                className='text-neutral-gray-700 small fw-semi-bold cursor-pointer'
                                onClick={() => setSavedSearchesOpen(!savedSearchesOpen)}
                                aria-controls="example-collapse-text"
                                aria-expanded={savedSearchesOpen}
                            >
                                Saved searches
                            </span>
                        </div>

                        <div ref={ref} className='position-relative w-100'>
                            <Collapse in={savedSearchesOpen} container={ref} className='position-absolute bg-dark-mode-700 pt-3 px-3 rounded-3 rounded-top-start-0 rounded-top-end-0 w-100 z-index-1'>
                                <div id="example-collapse-text">
                                    <>
                                        {
                                            savedSearches.length > 0 ?
                                                <div className=' pb-2'>
                                                    {savedSearches.sort((a, b) => a.name.localeCompare(b.name)).map((value, index) => (
                                                        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                            <p onClick={() => updateQuestion(value)} className='text-break cursor-pointer small mb-0'>{value.name}</p>
                                                            <span className="like-link-underline ms-2 cursor-pointer  extra-small" onClick={() => removeSearchTerm(value.id)}>Remove</span>
                                                        </div>
                                                    )
                                                    )}
                                                </div> :
                                                <p className="bg-dark-mode-300 p-3 rounded-3 mb-3 small">Searches will appear here once they have been saved.</p>
                                        }
                                    </>
                                </div>
                            </Collapse>
                        </div>


                    </div>
                    <Modal size="md" centered show={showModal}  onShow={handleOpenModal} onHide={handleCloseModal} >
                        <Modal.Body className='bg-dark-mode-200 rounded-2 position-relative'>
                            <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-300 text-black disc-md-45 btn-close-dark mt-n3 me-md-n35 me-n3"
                                onClick={handleCloseModal} >
                            </button>
                            <Form noValidate validated={saveSearchFormValidated} onSubmit={saveSearchTerm}>
                                <Row className="mb-3">
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Search name</Form.Label>
                                        <Form.Control size="md" className=' dark  bg-neutral-gray-900 border border-neutral-gray-900 fw-normal' type="text" placeholder="Enter name" required value={searchName} onChange={(e) => setSearchName(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter the search name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <button type="submit" className="py-2 px-4 btn bg-crayola-red-500  fw-semi-bold position-relative" disabled={saving}>
                                    Save search {saving && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                </button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    <ToastContainer position="bottom-end" className="p-4">
                        <Toast
                            className={toastType === 'remove' ? 'bg-secure-blue-500 border-0' : 'bg-fio-success-500 border-0'}
                            show={showToast}
                            onClose={() => setShowToast(false)}
                            delay={5000}
                            autohide
                        >
                            <Toast.Body className='d-flex justify-content-between align-items-center'>
                                <div>
                                    {toastType === 'remove' ? (
                                        <p className="mb-0 small fw-semi-bold text-white">
                                            Saved search term has been removed
                                        </p>
                                    ) : (
                                        <p className='mb-0 small fw-semi-bold text-white'>Search term has been saved</p>
                                    )}
                                </div>
                                <i
                                    className="fa-light fa-xmark text-white ms-3 cursor-pointer"
                                    onClick={() => setShowToast(false)}
                                ></i>

                            </Toast.Body>
                        </Toast>
                    </ToastContainer>

                    <NewsFeedListing searchParameters={searchParameters} setSearchParameters={setSearchParameters} setSearchPhrase={setSearchPhrase} onError={onError} />

                </>
                : <NoSearchTerms />

            }
        </>)}
        </div>
    );
}
