import React, { useState } from 'react';
import FilterSection from '../templates/FilterSection';
import UpgradeModal from '../templates/UpgradeModal';
import { Modal } from 'react-bootstrap';

const Filters = () => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [filters, setFilters] = useState({
    position: [],
    nationality: [],
    age: { from: '', to: '' },
  });

  const positionCounts = [3287, 3287, 3287, 3287, 1581, 1581, 1581, 1581, 231];
  const nationalityCounts = [114, 120, 12, 68, 32];

  const handleCheckboxChange = (section, value) => {
    setFilters((prevFilters) => {
      const newSectionFilters = prevFilters[section].includes(value)
        ? prevFilters[section].filter((item) => item !== value)
        : [...prevFilters[section], value];

      return { ...prevFilters, [section]: newSectionFilters };
    });
  };

  const handleAgeChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      age: { ...prevFilters.age, [field]: value },
    }));
  };

  const handleShowAllClick = (section) => {
    if (section === 'position') {
      setFilters((prevFilters) => ({
        ...prevFilters,
        position: ['Forward', 'Centre forward', 'Left wing', 'Right wing', 'Midfield', 'Defensive midfielder', 'Defense', 'Centre back', 'Goalkeeper']
      }));
    } else if (section === 'nationality') {
      setFilters((prevFilters) => ({
        ...prevFilters,
        nationality: ['Argentinian', 'British', 'French', 'Ghanaian', 'Spanish']
      }));
    }
  };

  const handleClearAll = () => {
    setFilters({
      position: [],
      nationality: [],
      age: { from: '', to: '' },
    });
  };

  const renderAppliedFilters = () => {
    const appliedFilters = [];

    if (filters.position.length > 0) {
      filters.position.forEach(filter => {
        appliedFilters.push(filter);
      });
    }

    if (filters.nationality.length > 0) {
      filters.nationality.forEach(filter => {
        appliedFilters.push(filter);
      });
    }

    if (filters.age.from || filters.age.to) {
      appliedFilters.push(`Age between ${filters.age.from || '0'} and ${filters.age.to || '100'}`);
    }

    return appliedFilters.map((filter, index) => (
      <span key={index} className="badge bg-crayola-red-400 fw-normal small  rounded-3 px-3 py-1 me-2 mb-2">
        <i className='fa-light fa-xmark me-2 small'></i>{filter}
      </span>
    ));
  };
 

  const handleCloseUpgradeModal = (event) => {
    if (event) event.stopPropagation();
    setShowUpgradeModal(false);
  };

  return (
    <div className='px-3 h-100 cursor-pointer'>
      <div className='z-index-n1 position-relative'>
        <div>
          <div className='d-flex justify-content-between align-items-end mb-md-6 mb-3'>
            <h5 className='fw-extra-bold mb-0'>Player results</h5>
            <div className='text-decoration-underline cursor-pointer' onClick={handleClearAll}>Clear all</div>
          </div>
          <div className='mb-4'>
            <h6 className='text-neutral-gray-800 fw-semi-bold'>Applied filters</h6>
            {renderAppliedFilters()}
          </div>
        </div>
        <FilterSection
          title="Position"
          options={['Forward', 'Centre forward', 'Left wing', 'Right wing', 'Midfield', 'Defensive midfielder', 'Defense', 'Centre back', 'Goalkeeper']}
          selected={filters.position}
          onChange={(value) => handleCheckboxChange('position', value)}
          counts={positionCounts}
          onShowAllClick={() => handleShowAllClick('position')}
        />
        <FilterSection
          title="Nationality"
          options={['Argentinian', 'British', 'French', 'Ghanaian', 'Spanish']}
          selected={filters.nationality}
          onChange={(value) => handleCheckboxChange('nationality', value)}
          counts={nationalityCounts}
          onShowAllClick={() => handleShowAllClick('nationality')}
        />
        <FilterSection
          title="Age"
          type="range"
          ageFrom={filters.age.from}
          ageTo={filters.age.to}
          onAgeChange={handleAgeChange}
        />
      </div>
      {showUpgradeModal && (
        <Modal size="lg" centered show={showUpgradeModal} onHide={handleCloseUpgradeModal}>
          <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
            <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
              onClick={handleCloseUpgradeModal} ></button>
            <div className='p-md-3'>
              <UpgradeModal />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default Filters;
