import React from 'react'

const GetInTouch = () => {
    return (
        <div className="container position-relative w-100 center-content" id="section-support" name="section-support">
            <div className="max-w-f-1400 w-100 bg-dark-mode-50 py-md-7 p-4 px-md-6 rounded-4 center-content flex-column">
                <h2 className="fw-extra-bold mb-5 text-crayola-red-500">We’d like to hear from you!</h2>
                <div className="row row-cols-1 row-cols-md-2 gx-25 gy-4 max-w-f-700">
                    <div className="col d-flex px-2 px-md-4">
                        <p className="extra-large">Whether you’re curious about product features, need a demonstration, want to provide feedback or have a press enquiry – we’re ready to answer any and all questions.</p>
                    </div>
                    <div className="col d-flex flex-column px-2 px-md-4">
                        <div className="d-flex align-items-center mb-3">
                            <i className="fa-regular fa-comments-question-check me-3"></i>
                            <p className="mb-0">Got a support question?</p>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <i className="fa-regular fa-circle-info me-3"></i>
                            <p className="mb-0">Would like to find out more about SportsKinetic.AI?</p>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <i className="fa-regular fa-photo-film me-3"></i>
                            <p className="mb-0">Do you have a media enquiry?</p>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <i className="fa-regular fa-handshake me-3"></i>
                            <p className="mb-0">Would you like to discuss partnering with SportsKinetic.AI?</p>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <a href="mailto:sarah@sportskinetic.ai" className="btn btn-lg rounded-2 bg-crayola-red-500  text-decoration-none fw-semi-bold">Contact us today</a>
                </div>
            </div>
        </div>
    )
}

export default GetInTouch