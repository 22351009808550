import React, { useState } from "react";
import ScoutAssist from '../components/ScoutAssist';
import OnesToWatch from '../components/OnesToWatch';
import Modal from "../templates/Modal";
import FeaturedNewsItem from "../components/FeaturedNewsItem";
import NewsGrid from "../components/NewsGrid";
import InsightSection from "../components/InsightsSection";
import { Link } from 'react-router-dom';

function Home() {

  const [modalMessage, setModalMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return <div>
    {showModal &&
      <Modal message={modalMessage} onClose={() => setShowModal(false)} />
    }
    <div className="my-5 my-md-7">
      <OnesToWatch onError={(message) => { setShowModal(true); setModalMessage(message) }} />
    </div>
    <div className="mb-5 mb-md-7">
      <ScoutAssist />
    </div>
    <div className="mb-5 mb-md-7">
      <InsightSection onError={(message) => { setShowModal(true); setModalMessage(message) }} />
    </div>
    <div className="d-grid-md grid-2-cols grid-gap-3 mb-4">
      <FeaturedNewsItem onError={(message) => { setShowModal(true); setModalMessage(message) }} />
      <NewsGrid onError={(message) => { setShowModal(true); setModalMessage(message) }} />
    </div> 
    
    
      <div className="d-flex justify-content-between align-items-center  bg-dark-mode-300 rounded-2 translate-icon-on-hover   position-relative b text-decoration-none px-6 py-5  w-100  mb-5 mb-md-7">
        <div className="d-none d-md-block">
          <h4 className="mb-0 fw-extra-bold">Personalised news and insights</h4>
          <p className="mb-0 text-neutral-gray-600">Explore the latest news curated to match your interests in the news and insights section</p>
        </div>
        <div className="w-100 w-md-auto">
        <Link to="/newsfeed" className="btn btn-md bg-crayola-red-500 w-100 text-decoration-none small fw-semi-bold  rounded-2">More news and insights<i className="ms-2 icon fa-solid fa-arrow-right small"></i></Link>
        </div>
      </div>

  </div>
}

export default Home;
