import React, { useEffect, useState, useRef } from "react"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { apiService } from "../../services/apiService";
import { authenticationService } from "../../services/authenticationService";
import PlayerCardTypeahead from "../../templates/PlayerCardTypeahead";

const WatchlistPlayerSearch = ({ onError, onSelect, isMobileView = false, onPlayerSelect }) => {
    const [options, setOptions] = useState([]);
    const [recommendedPlayer, setRecommendedPlayer] = useState([]);

    const refTypeahead = useRef(null);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/top')
            .then((data) => {
                setRecommendedPlayer(data.top_players);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {

                    }
                })
    }, []);

    const handleSearch = query => {
        //setIsLoading(true);
        // `http://sports.fenix:8888/football/search_player?search_query=${query}`
        //`${process.env.REACT_APP_API_URL}/player/typeahead_search?search_query=${query}`
        apiService.apiGet(`${process.env.REACT_APP_API_URL}/player/typeahead_search?search_query=${query}`)
            .then((items) => {
                setOptions(items.data.players)
                //setIsLoading(false)
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        if (onError) onError(error.message + ' error');
                    }
                })
    }

    useEffect(() => {
        refTypeahead.current.clear();
    }, []);

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true

    return (
        <AsyncTypeahead
            ref={refTypeahead}
            filterBy={filterBy}
            id="async-players"
            isLoading={false}
            open={true}
            labelKey={(option) => `${option.firstName} ${option.lastName}`}
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder="Search for a player to follow"
            inputProps={{
                className: "dark search-query bg-dark-mode-200 border border-dark-mode-200 fw-normal form-control form-control-md w-100",
            }}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                <input
                    {...inputProps}
                    ref={(input) => {
                        // Be sure to correctly handle these refs. In many cases, both can simply receive
                        // the underlying input node, but `referenceElementRef can receive a wrapper node if
                        // your custom input is more complex (See TypeaheadInputMulti for an example).
                        inputRef(input);
                        referenceElementRef(input);
                    }}
                />
            )}
            renderMenu={(results) => {
                return (
                    <div className="w-100 max-h-px-360 h-100 overflow-y-auto">
                        {results.length === 0 ? (
                            <>
                                {recommendedPlayer.length > 0 ? (
                                    recommendedPlayer.map((item, index) => {
                                        return (
                                            <div key={index} className="border-bottom border-dark-mode-200">
                                                <PlayerCardTypeahead
                                                    data={item}
                                                    status={true}
                                                    onSelect={(uuid) => onPlayerSelect(uuid, "add")}
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="text-center py-3">
                                        No recommended players
                                    </div>
                                )}
                            </>
                        ) : (
                            results.map((info, index) => (
                                <div key={index} className="d-flex flex-column justify-content-between border-bottom border-dark-mode-200">
                                    <PlayerCardTypeahead
                                        data={info}
                                        status={true}
                                        noImg={true}
                                        onSelect={(uuid) => onPlayerSelect(uuid, "add")}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                );
            }}

        />
    );
};

export default WatchlistPlayerSearch
