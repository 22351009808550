import React, { useState } from 'react';
import Modal from "../templates/Modal";
import LoginForm from "../forms/LoginForm";

const Login = () => {  
    
    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
  
    return (
        <div>
           
            {showModal &&
                <Modal message={modalMessage} onClose={() => setShowModal(false)} />
            }
            <main className="position-relative mt-5">
                <div className='container center-content'>
                    <div className="max-w-f-450 bg-dark-mode-300 p-5 rounded-4">    
                        <div className="p-0">
                            <div>
                                <h1 className="fw-extra-bold mb-3">Log in</h1>
                            </div>
                            <p className="fw-normal mb-4">
                                Welcome back to SportsKinetic.ai. Log in to continue your journey.
                            </p>
                            <LoginForm  onError={(message) => { setShowModal(true); setModalMessage(message) }}/>
                            
                            <p className="center-content mt-3 extra-small">
                                Don't have an account?
                                <a href="mailto:sarah@sportskinetic.ai" className="text-crayola-red-400 fw-semi-bold ms-1">Contact us</a>
                            </p>
                        </div>                        
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Login;
