import NewsFeedNewsSummaryCard from "../../templates/NewsFeed/NewsFeedNewsSummaryCard";
import React, { useEffect, useState } from 'react';
import { authenticationService } from '../../services/authenticationService';
import { apiService } from '../../services/apiService';
import Loader from '../../templates/Loader';
import { Link } from "react-router-dom";

export default function NewsSummariesListing({ searchParameters, onError }) {
   
    const [loadingData, setLoadingData] = useState(false);
    const [results, setResults] = useState([]);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [visibleCards, setVisibleCards] = useState(2);

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 2);
    };

    const viewResults = results.slice(0, visibleCards);


    useEffect(() => {

        setVisibleCards(2);
        setLoadingData(true);

        const topMentionsFetch = apiService.apiGet(`${process.env.REACT_APP_API_URL}/mynews/keyword_search?search_keyword=${searchParameters.question}&page=1`);
        const summariesFetch = apiService.apiGet(`${process.env.REACT_APP_API_URL}/mynews/summaries?search_keyword=${searchParameters.question}&page=1`);

        Promise.all([topMentionsFetch, summariesFetch])
            .then(([dataTopMentions, dataSummaries]) => {
                let summaries = dataSummaries.summaries.filter((i) => i.text !== '');
                let topMentions = dataTopMentions.data.top_players.map(player => `${player.first_name} ${player.last_name}`);
                let summariesLength = summaries.length;

                //all kind of sort shinanigans,want to sort summaries in order of top mentions
                const itemPositions = {};
                for (const [index, id] of topMentions.entries()) {
                    itemPositions[id] = index;
                }
                summaries.sort(function (a, b) {
                    return (itemPositions[a.following_name] ?? summariesLength) - (itemPositions[b.following_name] ?? summariesLength);
                });

                setResults(summaries);             
                setLoadingData(false);
                setResultsTotal(summaries.length);

            })
            .catch(error => {
                setResults([]);
                setLoadingData(false);
               
                if ('401' === error.message) {
                    authenticationService.logout();
                } else {
                    onError(error.message + ' error');
                }
            });

    }, [onError,searchParameters ])


    return (
        <div className='mb-6'>
            {loadingData ? (
                <Loader />
            ) : (
                <div className="">
                    {0 < viewResults.length && <div className="d-flex justify-content-between mb-3">
                        <p className="large fw-semi-bold mb-0 ">News summary for your watchlist players</p>
                        {false && <Link to="/newsfeed" className="text-decoration-none text-crayola-red-400 small flex-0 d-none d-md-inline">
                            View all summaries<i className="ms-3 fa-light fa-arrow-right small"></i>
                        </Link>}
                    </div>}
                    <div>
                        {0 < viewResults.length &&
                            <div className=" ">
                                {viewResults.map(function (object, i) {

                                    return <div key={i} className="mb-3">
                                        <NewsFeedNewsSummaryCard data={object} following={true} />
                                    </div>
                                })
                                }
                            </div>
                        }
                    </div>
                    {loadingData && <Loader />}
                    {visibleCards < resultsTotal && (
                       <div className="center-content"> <button
                            onClick={loadMoreHandler}
                            className="btn btn-lg bg-crayola-red-500 px-55 rounded-pill small fw-semi-bold position-relative"
                            disabled={loadingData}
                        >
                            Show more {loadingData && <span className="spinner-border spinner-border-sm position-absolute top-50 start-50 text-white mx-n2 mt-n2" role="status" aria-hidden="true"></span>}
                        </button></div>
                    )}

                </div>
            )
            }
        </div>
    )
}