import React, { useEffect, useState } from 'react'
import { apiService } from '../../services/apiService';
import { authenticationService } from '../../services/authenticationService';
import { Modal, Toast, ToastContainer } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import WatchlistPlayerSearch from './WatchlistPlayerSearch';
import WatchlistPlayerCard from '../../templates/WatchlistPlayerCard';
import Pagination from '../../templates/Pagination';
import Loader from '../../templates/Loader';
import HelperFunctions from '../../helpers/HelperFunctions';
import UnfollowCard from '../../templates/UnfollowCard';
const ManageWatchlist = ({ onError }) => {

    const [loadingData, setLoadingData] = useState(true);
    const [playerData, setPlayerData] = useState([]);
    const [addedPlayerInfo, setAddedPlayerInfo] = useState(null);
    const [unfollowPlayerInfo, setUnfollowPlayerInfo] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('');
    const [isWatchlistUpdated, setIsWatchlistUpdated] = useState(false);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageSize, setPageSize] = useState(8);
    const [page, setPage] = useState(1);
    const [visibleCards, setVisibleCards] = useState(10);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showFollowModal, setShowFollowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleCloseModal = () => setShowAddModal(false);
    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseFollowModal = () => setShowFollowModal(false);

    const { firstNameExtracted } = HelperFunctions();

    const pageSizeEventHandler = (page_size) => {
        setPageSize(page_size);
        setPage(1);
    };

    const paginationEventHandler = (page) => {
        setPage(parseInt(page, 8));
    };

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/watchlist')
            .then((data) => {
                const players = Array.isArray(data) ? data : [];
                setPlayerData(players);
                setResultsTotal(players.length);
                setPagesTotal(Math.max(1, Math.ceil(players.length / pageSize)));
                setLoadingData(false);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, isWatchlistUpdated]);

    const handleUnfollow = (data) => {
        const payload = `remove=${encodeURIComponent(data.uuid)}`;
        const deletedId = data.uuid;
        apiService
            .apiPostJson(`${process.env.REACT_APP_API_URL}/mynews/remove`, payload, 'application/x-www-form-urlencoded')
            .then(() => {
                setPlayerData((prevData) => {
                    const updatedData = prevData.filter((player) => player.uuid !== deletedId);
                    const updatedTotal = updatedData.length;
                    const updatedPages = Math.max(1, Math.ceil(updatedTotal / pageSize));
                    if (page > updatedPages) {
                        setPage((prevPage) => Math.max(1, prevPage - 1)); // Go to previous valid page
                    }
                    setResultsTotal(updatedTotal);
                    setPagesTotal(updatedPages);
                    return updatedData;
                });
                setShowFollowModal(false);
                setToastType('remove');
                setUnfollowPlayerInfo(data);
                setShowToast(true);
            })
            .catch((error) => {
                onError(error.message + ' error');
            });
    };

    const manageWatchlistPlayer = (player, action) => {
        apiService.apiPostJson(`${process.env.REACT_APP_API_URL}/mynews/save/${player.uuid}`)
            .then(() => {
                if (action === 'add') {
                    setAddedPlayerInfo(player);
                    setToastType('add');
                    setIsWatchlistUpdated((prev) => !prev);
                    setShowToast(true);
                }
            })
            .catch(error => {
                onError(error.message + ' error');
            });
    };

    useEffect(() => {
        setPagesTotal(Math.ceil(resultsTotal / pageSize));
    }, [resultsTotal, pageSize]);

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 8);
    };

    const currentPageResults = playerData.slice((page - 1) * pageSize, page * pageSize);
    const mobileViewResults = playerData.slice(0, visibleCards);

    const firstName = addedPlayerInfo?.firstName;
    const lastName = addedPlayerInfo?.lastName;

    const handleFollowingClick = (data) => {
        setShowFollowModal(true);
        setModalData(data);
    };

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h4 className="large bold mb-0">Manage your watchlist</h4>
                <div className="btn btn-lg bg-crayola-red-500 text-decoration-none small flex-0 fw-semi-bold rounded-2 d-md-block d-none" onClick={handleShowAddModal}><i className='fa-solid fa-plus me-2'></i>Add a player</div>
                <div className="btn btn-md bg-crayola-red-500 text-decoration-none small flex-0 fw-semi-bold rounded-2 d-md-none d-block" onClick={handleShowAddModal}><i className='fa-solid fa-plus me-2'></i>Add</div>
            </div>
            {
                loadingData ? (
                    <div className="ratio ratio-21x9">
                        <div className='center-content'>
                            <Loader />
                        </div>
                    </div>
                ) : (
                    <>
                        {playerData.length > 0 ? (
                            <>
                                <div className='mb-3 d-md-block d-none'>
                                    {currentPageResults.map(function (info, i) {
                                        return <div className='d-flex align-items-center' key={i}>
                                            <Link to={`/playerdetail/${info.uuid}`} className='mb-3 bg-dark-mode-500 rounded-3 position-relative text-decoration-none  py-2half px-4 w-100'>
                                                <WatchlistPlayerCard data={info}
                                                    onFollowingClick={handleFollowingClick} />
                                            </Link>
                                        </div>
                                    })}
                                </div>
                                <div className='mb-2 d-md-none d-block'>
                                    {mobileViewResults.map(function (info, i) {
                                        return <Link to={`/playerdetail/${info.uuid}`} key={i} className='text-decoration-none'>
                                            <div className='mb-2 bg-dark-mode-500 rounded-3 position-relative py-2half w-100'>
                                                <WatchlistPlayerCard data={info}
                                                    onFollowingClick={handleFollowingClick} />
                                            </div>
                                        </Link>
                                    })}
                                </div>
                                {1 < pagesTotal &&
                                    <div className='d-md-flex d-none align-items-center w-100 mt-4 justify-content-center'>
                                        <Pagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize} />
                                    </div>
                                }
                                {visibleCards < resultsTotal && (
                                    <button onClick={loadMoreHandler} className="btn btn-md d-md-none d-block bg-crayola-red-500  w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold mt-3">
                                        Show more
                                    </button>
                                )}
                                <ToastContainer position="bottom-end" className="p-4">
                                    <Toast
                                        className={toastType === 'remove' ? 'bg-secure-blue-500 border-0' : 'bg-fio-success-500 border-0'}
                                        show={showToast}
                                        onClose={() => setShowToast(false)}
                                        delay={5000}
                                        autohide
                                    >
                                        <Toast.Body className='d-flex justify-content-between align-items-center'>
                                            <div>
                                                {toastType === 'remove' ? (
                                                    <>
                                                        <p className="mb-1 small fw-semi-bold text-white">
                                                            1 player have been unfollowed
                                                        </p>
                                                        <p className="mb-0 small text-white">
                                                            {firstNameExtracted(unfollowPlayerInfo?.first_name)} {unfollowPlayerInfo?.last_name} been unfollowed.
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className='mb-1 extra-small fw-semi-bold text-white'>You are now following {firstNameExtracted(firstName)} {lastName}</p>
                                                        <p className='mb-0 extra-small text-white'>Targeted news is being collected and evaluated.</p>
                                                    </>
                                                )}
                                            </div>
                                            <i
                                                className="fa-light fa-xmark text-white ms-3 cursor-pointer"
                                                onClick={() => setShowToast(false)}
                                            ></i>
                                        </Toast.Body>
                                    </Toast>
                                </ToastContainer>
                            </>
                        ) : (
                            <>No players have been added to your watchlist. To follow a player, please use the search in the page to add a player.</>
                        )}
                        <Modal size="lg" centered show={showAddModal} onHide={handleCloseModal}>
                            <Modal.Body className='bg-dark-mode-700 rounded-3 position-relative min-h-px-510 max-h-px-510 h-100'>
                                <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-300 text-black disc-md-45 btn-close-dark mt-n3 me-md-n35 me-n3"
                                    onClick={handleCloseModal} >
                                </button>
                                <div className='p-md-3'>
                                    <h4 className='fw-semibold mb-3'>Search a player to add</h4>
                                    <div className='react-bootstrap-typeahead-desktop w-100 mb-3'>
                                        <WatchlistPlayerSearch onPlayerSelect={manageWatchlistPlayer} />
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal size="sm" centered show={showFollowModal} onHide={handleCloseModal} >
                            <Modal.Body className='bg-dark-mode-500 rounded-3 position-relative'>
                                <UnfollowCard data={modalData} handleCloseFollowModal={handleCloseFollowModal} handleUnfollow={handleUnfollow} />
                            </Modal.Body>
                        </Modal>
                    </>
                )
            }
        </div>
    )
}

export default ManageWatchlist