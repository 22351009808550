import { NavLink } from 'react-router-dom';


export default function SavedDataMenu({ onError }) {
  

    return (

               
            <ul className="list-unstyled  mb-0">
                <li className="nav-item mb-1">
                    <NavLink to={'/saved/watchlist'} activeclassname="active" className="btn p-2  fw-normal fw-active-semi-bold   bg-transparent bg-active-dark-mode-200 bg-hover-dark-mode-200 d-flex justify-content-between align-items-center show-item-on-hover-or-active text-decoration-none border-0 small " >
                        <div>Manage your watchlist</div>
                        <div>
                            <div className="item">
                                <i className="fa-light fa-angle-right"></i>
                            </div>
                        </div>
                    </NavLink>
                </li>
                <li className="nav-item mb-1">
                    <NavLink to={'/saved/news'} activeclassname="active" className="btn p-2  fw-normal fw-active-semi-bold   bg-transparent bg-active-dark-mode-200 bg-hover-dark-mode-200 d-flex justify-content-between align-items-center show-item-on-hover-or-active text-decoration-none border-0 small " >
                        <div>Saved articles</div>
                        <div>
                            <div className="item">
                                <i className="fa-light fa-angle-right"></i>
                            </div>
                        </div>
                    </NavLink>
                </li>
                <li className="nav-item mb-1">
                    <NavLink to={'/saved/searches'} activeclassname="active" className="btn p-2  fw-normal fw-active-semi-bold   bg-transparent bg-active-dark-mode-200 bg-hover-dark-mode-200 d-flex justify-content-between align-items-center show-item-on-hover-or-active text-decoration-none border-0 small " >
                        <div>Saved searches</div>
                        <div>
                            <div className="item">
                                <i className="fa-light fa-angle-right"></i>
                            </div>
                        </div>
                    </NavLink>
                </li>

            </ul>
     
    )
}