import { useState } from 'react';
import { Modal, Carousel } from "react-bootstrap";
import { Link } from 'react-router-dom';
import InsightCard from '../templates/InsightCard';
export default function AIInsights({ props, data }) {

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const handleSelect = (selectedIndex) => {
        setCarouselIndex(selectedIndex);
        handleShowModal();
    };


    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    return (
        <div>
            <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseModal} ></button>
                    <div className='p-md-3'>
                        <Carousel activeIndex={carouselIndex} onSelect={handleSelect}>
                            {
                                data.slice(0, 5).map(function (insight, i) {
                                    return <Carousel.Item
                                        key={i}
                                    >
                                        <div className='my-55 mx-65'>
                                            <p>{insight}</p>
                                        </div>
                                    </Carousel.Item>
                                })
                            }
                        </Carousel>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="d-grid fixed-5-plus-half-row grid-gap-3">
                {
                    data.slice(0, 5).map((object, i) => (
                        <div onClick={() => handleSelect(i)} key={i}>
                            <InsightCard insights={object} />
                        </div>
                    ))
                }
                <div className="center-content">
                    <Link to="/ai-insights" className="bg-dark-mode-500 center-content text-decoration-none rounded-circle disc-7">
                        <i className="fa-light fa-arrow-right small"></i>
                    </Link>
                </div>
            </div>
        </div>
    );
}
