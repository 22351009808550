import React, { useEffect, useState } from 'react'
import { apiService } from '../services/apiService';
import { authenticationService } from '../services/authenticationService';
import NewsFeedNewsSummaryCard from '../templates/NewsFeed/NewsFeedNewsSummaryCard';
import Pagination from '../templates/Pagination';
import Loader from '../templates/Loader';
import { Carousel, Modal } from 'react-bootstrap';
import { debounce } from 'lodash';

const InsightListing = ({ onError }) => {

    const [loadingData, setLoadingData] = useState(true);
    const [results, setResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);

    const [resultsTotal, setResultsTotal] = useState(0);
    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageSize, setPageSize] = useState(18);
    const [page, setPage] = useState(1);
    const [visibleCards, setVisibleCards] = useState(10);

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState('');

 

    const handleCarouselSelect = (selectedIndex) => {
        setCarouselIndex(selectedIndex);
    };

    const handleCloseModal = () => setShowModal(false);

    const pageSizeEventHandler = (page_size) => {
        setPageSize(page_size);
        setPage(1);
    };

    const paginationEventHandler = (page) => {
        setPage(parseInt(page, 10));
    };

    // const insightCount = 30;

    useEffect(() => {
        // let data = new FormData();
        // data.append('count', insightCount);

        apiService.apiPostForm(process.env.REACT_APP_API_URL + '/news/list_news_summary')
            .then((resp) => {
                setResults(resp.data);
                setFilteredResults(resp.data)
                setResultsTotal(resp.data.length);
                setPagesTotal(Math.ceil(resp.data.length / pageSize));
                setLoadingData(false);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);

    useEffect(() => {
        setPagesTotal(Math.ceil(resultsTotal / pageSize));
    }, [resultsTotal, pageSize]);

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 10);
    };

    const debounceOnChange = debounce((value) => {
        const tempFilteredResults = results.filter((item) => item.following_name.toLowerCase().includes(value.toLowerCase()) || item.text.toLowerCase().includes(value.toLowerCase()));
        setFilteredResults(tempFilteredResults);
        resetResultPaging(tempFilteredResults);
    }, 500);

    const removeSearchPhrase = () => {
        setSearchPhrase('');
        setFilteredResults(results);
        resetResultPaging(results);
    }

    const resetResultPaging = (arrResults) => {
        setPage(1);
        setResultsTotal(arrResults.length);
        setPagesTotal(Math.ceil(arrResults.length / pageSize));
    };

    const currentPageResults = filteredResults.slice((page - 1) * pageSize, page * pageSize);
    const mobileViewResults = filteredResults.slice(0, visibleCards);

    return (
        <div className='h-100'>
            {loadingData ? (
                <div className="ratio ratio-21x9">
                    <div className='center-content'>
                        <Loader />
                    </div>
                </div>
            ) : (
                <>
                    {results.length > 0 ? (
                        <>
                            <div className="btn-group focus-dark  w-100  me-3  rounded-2 rounded-top-start-0 rounded-bottom-start-0 mb-3">
                                <div className="ps-2 border border-1 py-3 pe-0 border-dark-mode-50 rounded-2 rounded-top-end-0 border-end-0 rounded-bottom-end-0 bg-dark-mode-50 text-decoration-none  small position-relative center-content"><i className="fa-light fa-search"></i></div>
                                <input type="text" className={'py-25 px-2 text-white bg-dark-mode-50 border-1 border-start-0 border-end-0 border-dark-mode-50 form-control form-control-lg rounded-0'} placeholder="Search AI insights.." required value={searchPhrase} onChange={(e) => { setSearchPhrase(e.target.value); debounceOnChange(e.target.value); }} />

                                {0 < searchPhrase.length ?
                                    <button type="button" onClick={() => removeSearchPhrase()} className=" btn btn-link border-1 border-dark-mode-50 rounded-2 rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"><i className="fa-solid fa-remove"></i></button>
                                    :
                                    <div className=" text-crayola-red-500 btn btn-link border-1 border-dark-mode-50 rounded-2 rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"></div>
                                }
                            </div>

                            <div className='d-md-flex d-none justify-content-between align-items-center mb-5'>
                                <p className='mb-0'>
                                    Showing {((page - 1) * pageSize) + 1}-{Math.min(page * pageSize, resultsTotal)} of {resultsTotal} result{(resultsTotal === 1) ? '' : 's'}
                                </p>
                            </div>
                            <div className="d-grid-md grid-gap-3 grid-md-3-cols position-relative d-md-block d-none">
                                {currentPageResults.map((value, index) => (
                                    <NewsFeedNewsSummaryCard data={value} textLimit={false} />                                    
                                ))}
                            </div>
                            {1 < pagesTotal &&
                                <div className='d-md-block d-none mt-4'>
                                    <Pagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize} />
                                </div>
                            }
                            <div className="d-md-none d-block">
                                <p className='fw-semi-bold mb-3 small'>Player insights</p>
                                <div className='d-grid grid-gap-2half grid-2-cols position-relative'>
                                    {mobileViewResults.map((value, index) => (
                                        <NewsFeedNewsSummaryCard data={value} textLimit={false} />
                                    ))}
                                </div>
                                {1 < pagesTotal && visibleCards < resultsTotal && (
                                    <button onClick={loadMoreHandler} className="btn btn-md bg-crayola-red-500  w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold mt-3">
                                        Show more
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (
                        <>No results</>
                    )}
                </>
            )}
            <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseModal} ></button>
                    <div className='p-md-3'>
                        <Carousel activeIndex={carouselIndex} onSelect={handleCarouselSelect}>
                            {results.map((insight, i) => (
                                <Carousel.Item key={i}>
                                    <div className="my-55 mx-65">
                                        <p>{insight}</p>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default InsightListing