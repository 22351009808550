import { useState } from "react";
import { Accordion } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import HelperFunctions from "../helpers/HelperFunctions";

export default function LeagueStatistics({ props, stats }) {
    const { formatValue } = HelperFunctions();

    const allStats = Object.entries(stats || {})
        .flatMap(([league, years]) =>
            Object.entries(years).map(([year, data]) => ({ league, year, data }))
        );

    const sortedStats = allStats.sort((a, b) => parseInt(b.year) - parseInt(a.year));
    const initialActiveKey = sortedStats.length > 0 ? `${sortedStats[0].league}-${sortedStats[0].year}` : null;

    const [activeAccordionKey, setActiveAccordionKey] = useState(initialActiveKey);

    function CustomToggle({ children, eventKey }) {
        const isCurrent = activeAccordionKey === eventKey;

        const decoratedOnClick = useAccordionButton(eventKey, () => {
            setActiveAccordionKey(isCurrent ? null : eventKey);
        });

        return (
            <button
                type="button"
                className="bg-dark-mode-500 border-0 mb-3 w-100 rounded-2 p-0"
                onClick={decoratedOnClick}
            >
                <div className="d-flex justify-content-between align-items-center  py-md-3 p-2half px-md-4">
                    {children}
                    <i className={`fa ${isCurrent ? 'fa-minus' : 'fa-plus'} text-white`}></i>
                </div>
            </button>
        );
    }


    return (
        <div>
            <p className="large d-md-block d-none fw-semi-bold">Cup and league statistics</p>
            <p className="small d-md-none d-block fw-semi-bold mb-2">Cup and league statistics</p>

            <Accordion activeKey={activeAccordionKey}>
                {sortedStats.map((item, index) => {
                    const uniqueKey = `${item.league}-${item.year}`;
                    return (
                        <div key={uniqueKey}>
                            <CustomToggle eventKey={uniqueKey}>
                                <div className='d-flex align-items-center justify-content-between text-white'>
                                    <div className="d-flex flex-row align-items-center">
                                        <img className="object-fit-contain bg-white rounded-1 w-px-58 h-px-58 mb-0 me-3" src={item.data.Discipline[0].competition.image} alt={item} />
                                        <div className='d-flex flex-column align-items-start'>
                                            <p className="mb-0 d-md-block d-none">{item.league} - {item.year}</p>
                                            <p className="mb-0 d-md-none text-start d-block small">{item.league} - {item.year}</p>
                                            <div className="center-content">
                                                <ReactCountryFlag
                                                    countryCode={item.data.Discipline[0]?.competition.country?.code2}
                                                    svg
                                                    className="disc-4"
                                                    title={item.data.Discipline[0]?.competition.country?.name}
                                                />
                                                <p className="mb-0 text-neutral-gray-700 ms-2 fw-normal small d-md-block d-none">{item.data.Discipline[0]?.competition.country?.name}</p>
                                                <p className="mb-0 text-neutral-gray-700 ms-2 fw-normal extra-small d-md-none d-block">{item.data.Discipline[0]?.competition.country?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CustomToggle>
                            <Accordion.Item eventKey={uniqueKey} className="border-0 bg-dark-mode-600">
                                <Accordion.Body className="py-md-4 mb-3">
                                    <div className="row row-cols-md-3 row-cols-1 gx-4 px-md-3 px-2half">
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Games (appearances)</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Games (appearances)</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Minutes</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Minutes</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Minutes</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Appearances?.[0]?.minutes)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small"> {formatValue(item.data.Appearances?.[0]?.minutes)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Appearances</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Appearances</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Appearances</div>
                                                </div>
                                                <div className=" d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.data.Appearances?.[0].appearances)}</div>
                                                <div className="  d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.data.Appearances?.[0].appearances)}</div>
                                                <div className=" d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Starting line-ups</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Starting line-ups</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Starting line-ups</div>
                                                </div>
                                                <div className=" d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Appearances?.[0].starting_lineups)}</div>
                                                <div className="  d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Appearances?.[0].starting_lineups)}</div>
                                                <div className=" d-flex align-items-center py-md-3 py-2  bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Captain</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Captain</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Captain</div>
                                                </div>
                                                <div className=" d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.data.Appearances?.[0].captain)}</div>
                                                <div className="  d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.data.Appearances?.[0].captain)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Goals</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Goals</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total goals</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total goals</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total goals</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Goals[0]?.total_goals)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Goals[0]?.total_goals)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2  bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Goals conceded</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Goals conceded</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Goals conceded</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.data.Goals[0]?.goals_conceded)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.data.Goals[0]?.goals_conceded)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Assists</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Assists</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Assists</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Goals[0]?.assists)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Goals[0]?.assists)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2  bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Saves</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Saves</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Saves</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.data.Goals[0]?.saves)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.data.Goals[0]?.saves)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Substitutions</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Substitutions</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Substitutions in</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Substitutions in</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Substitutions in</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Substitutions[0]?.in_count)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Substitutions[0]?.in_count)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2  bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Substitutions out</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Substitutions out</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Substitutions out</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.data.Substitutions[0]?.out_count)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.data.Substitutions[0]?.out_count)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Bench count</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Bench count</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Bench count</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Substitutions[0]?.bench_count)}</div>
                                                <div className=" d-md-none d-flex aligns-center fw-normal extra-small">{formatValue(item.data.Substitutions[0]?.bench_count)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Discipline</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Discipline</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Yellow cards</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Yellow cards</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Yellow cards</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Discipline[0]?.yellow_cards)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Discipline[0]?.yellow_cards)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Second yellows</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Second yellows</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Second yellows</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.data.Discipline[0]?.yellow_red_cards)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.data.Discipline[0]?.yellow_red_cards)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Red cards</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Red cards</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Red cards</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Discipline[0]?.red_cards)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Discipline[0]?.red_cards)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Tackles</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Tackles</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total Tackles</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total Tackles</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total Tackles</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Tackles[0]?.total_tackles)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Tackles[0]?.total_tackles)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Blocks</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Blocks</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Blocks</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.data.Tackles[0]?.blocks)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.data.Tackles[0]?.blocks)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Interceptions</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Interceptions</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Interceptions</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Tackles[0]?.interceptions)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Tackles[0]?.interceptions)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Fouls</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Fouls drawn</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Fouls drawn</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Fouls drawn</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Fouls[0]?.fouls_drawn)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Fouls[0]?.fouls_drawn)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Fouls commited</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Fouls commited</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Fouls commited</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.data.Fouls[0]?.fouls_committed)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.data.Fouls[0]?.fouls_committed)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Dribbling</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Dribbling</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Dribble attempts</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Dribble attempts</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Dribble attempts</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Dribbling[0]?.dribble_attempts)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Dribbling[0]?.dribble_attempts)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Successful dribbles</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Successful dribbles</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Successful dribbles</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.data.Dribbling[0]?.successful_dribbles)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.data.Dribbling[0]?.successful_dribbles)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Past dribbles</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Past dribbles</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Past dribbles</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Dribbling[0]?.past_dribbles)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Dribbling[0]?.past_dribbles)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Duels</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Duels</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total duels</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total duels</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total duels</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Duels[0]?.total_duels)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Duels[0]?.total_duels)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Duels won</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Duels won</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Duels won</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.data.Duels[0]?.duels_won)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.data.Duels[0]?.duels_won)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Shots</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Shots</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total shots</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total shots</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total shots</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Shots[0]?.total_shots)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Shots[0]?.total_shots)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Shots on target</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Shots on target</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Shots on target</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.data.Shots[0]?.shots_on_target)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.data.Shots[0]?.shots_on_target)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-0 mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Penalties</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Penalties</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties scored</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties scored</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties scored</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Penalties[0]?.penalties_scored)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Penalties[0]?.penalties_scored)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties missed</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties missed</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties missed</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.data.Penalties[0]?.penalties_missed)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.data.Penalties[0]?.penalties_missed)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties won</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties won</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties won</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Penalties[0]?.penalties_won)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Penalties[0]?.penalties_won)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2 bg-dark-mode-500 ">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties commited</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties commited</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties commited</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small bg-dark-mode-500 ">{formatValue(item.data.Penalties[0]?.penalties_committed)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small bg-dark-mode-500 ">{formatValue(item.data.Penalties[0]?.penalties_committed)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Penalties saved</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Penalties saved</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Penalties saved</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Penalties[0]?.penalties_saved)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Penalties[0]?.penalties_saved)}</div>
                                            </div>
                                        </div>
                                        <div className="mb-md-5half mb-3">
                                            <p className="mb-2 fw-semi-bold d-md-block d-none">Passes</p>
                                            <p className="mb-2 d-md-none d-flex align-items-center fw-normal extra-small">Passes</p>
                                            <div className="row row-cols-2 bg-dark-mode-300 rounded-2">
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Total passes</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Total passes</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Total passes</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Passes[0]?.total_passes)}</div>
                                                <div className="d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Passes[0]?.total_passes)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2  bg-dark-mode-500">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Key passes</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Key passes</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Key passes</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small  bg-dark-mode-500">{formatValue(item.data.Passes[0]?.key_passes)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small  bg-dark-mode-500">{formatValue(item.data.Passes[0]?.key_passes)}</div>
                                                <div className="d-flex align-items-center py-md-3 py-2">
                                                    <div className="fw-semi-bold pe-2 d-lg-block d-none">Pass accuracy</div>
                                                    <div className="fw-semi-bold pe-2 small d-md-block d-lg-none d-none">Pass accuracy</div>
                                                    <div className="fw-semi-bold pe-2 extra-small d-md-none d-block">Pass accuracy</div>
                                                </div>
                                                <div className="d-md-flex d-none align-items-center fw-normal small">{formatValue(item.data.Passes[0]?.pass_accuracy)}</div>
                                                <div className=" d-md-none d-flex align-items-center fw-normal extra-small">{formatValue(item.data.Passes[0]?.pass_accuracy)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    );
                })}
            </Accordion>
        </div>
    );
}
