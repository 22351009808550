import { Link } from 'react-router-dom';
import PlayerCard from '../templates/PlayerCard';
import { useEffect, useState } from 'react';
import { apiService } from '../services/apiService';
import { authenticationService } from '../services/authenticationService';

export default function SimilarPlayers({ props, onError }) {
    const [playerData, setPlayerData] = useState([]);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/players/ones-to-watch')
            .then((data) => {
                setPlayerData(data.data)
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }   
                })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <p className="large d-md-block d-none fw-semi-bold">Similar players</p>
            <p className="small d-md-none d-block fw-semi-bold mb-2">Similar players</p>
            <div className="d-grid-md  grid-gap-3 grid-md-5-cols pb-2 pb-md-0 position-relative" >
                {playerData.slice(0,5).map(function (info, i) {
                    return <Link key={i} to={`/playerdetail/${info.uuid}`} className={'d-flex flex-column justify-content-between flex-none flex-md-auto me-1half me-md-0 cursor-pointer mb-md-0 mb-2half w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none  pt-2half pt-md-4 translate-icon-on-hover' + (i < 3 ? '  d-flex' : ' d-none d-md-flex')}><PlayerCard data={info} /></Link>
                })}
            </div>
        </>
    )
}

