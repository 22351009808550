import HelperFunctions from '../../helpers/HelperFunctions';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';

export default function SavedSearchCard({ data = {}, inMegaMenu = false }) {

    const navigate = useNavigate();

    const { name } = data;
    const { stringTruncate, } = HelperFunctions();

    const loadQuestion = (savedSearch) => {

        let data = new FormData();
        data.append('id', savedSearch.id);

        apiService.apiPostForm(process.env.REACT_APP_API_URL + '/mynews/searchterms/update_search_term_last_accessed', data);

        //window.location
        //redirect to search page
        navigate({
            pathname: '/newsfeed',
            search: `?question=${savedSearch.question}`,
            state:{ question: savedSearch.question }
        });

    }

    const questionLimit = inMegaMenu ? 25 : 40;

    return (
        <div
            onClick={() => loadQuestion(data)}
            className="d-flex justify-content-between w-100 bg-zero-dark-300 p-3 rounded-3 cursor-pointer white-icon-on-hover border-on-hover">
            <p className='mb-0 small text-white text-break'>{stringTruncate(name, questionLimit)}</p>
            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon white text-neutral-gray-800 fa-light fa-arrow-up-right small"></i></div>
        </div>
    );
}
