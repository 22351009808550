
import React, { useState , useCallback} from "react";
import NewsFeedComponent from "../../components/NewsFeed/NewsFeedComponent";
import Breadcrumb from '../../templates/Breadcrumb';
import Modal from "../../templates/Modal";

function NewsFeed() {

    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);


    const onError = useCallback((message) => {
        setShowModal(true); 
        setModalMessage(message) 
    },[]);

    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'News and insights' },
    ];

    return <div >
        {showModal &&
            <Modal message={modalMessage} onClose={() => setShowModal(false)} />
        }
        <Breadcrumb items={breadcrumbItems}/>
        <div className="center-content">
            <div className="mb-md-7 mb-5 w-100  max-w-px-680">
                <NewsFeedComponent onError={onError} />
            </div>
        </div>
       
    </div>
}

export default NewsFeed;
