import { Link } from 'react-router-dom';
import nonews from '../../images/nonews.svg'

export default function NoSearchTerms({ props }) {
    return (
        <div className='center-content'>
            <div className=''>
                <div className="">
                    <div className='center-content mb-4'>
                        <img src={nonews} alt='nonews' />
                    </div>
                    <div className='center-content flex-column px-md-3 px-6 max-w-px-md-450'>
                        <h2 className='fw-bold text-center mb-2 d-md-block d-none'>No news is bad news...<br />let’s fix that!</h2>
                        <h2 className='fw-bold text-center mb-2 d-md-none d-block'>No news is bad news...let’s fix that!</h2>
                        <p className='text-center d-md-block d-none text-neutral-gray-600 mb-5'>Kick off your news feed by adding a player of interest or searching something that interests you.</p>
                        <p className='text-center d-md-none d-block extra-small text-neutral-gray-600 mb-4'>Kick off your news feed by adding a player of interest or searching something that interests you.</p>
                        <Link to='/saved/watchlist' className="btn btn-lg w bg-crayola-red-500 text-decoration-none small flex-0 fw-semi-bold rounded-2" >Search a player to watch</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}