import { useEffect, useState } from 'react';

import { apiService } from '../services/apiService';
import Loader from '../templates/Loader';
import AIInsights from './AIInsights';
import { authenticationService } from '../services/authenticationService';

export default function InsightSection({ props, onError }) {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);

    const insightCount = 5;

    useEffect(() => {

        let data = new FormData();
        data.append('count', insightCount);

        apiService.apiPostForm(process.env.REACT_APP_API_URL + '/news/list_insights', data)
            .then((resp) => {
                setData(resp.data);
                setLoadingData(false);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loadingData ? (
                <Loader />
            ) : (
                <>
                    <div>
                        <div className="mb-2">
                            <div className="d-flex justify-content-between">
                                <p className="large d-md-block d-none fw-semi-bold mb-2"><i className="fa-light fa-sparkles me-2 "></i>AI insights</p>
                                <p className="small d-md-none d-block fw-semi-bold mb-2"><i className="fa-light fa-sparkles me-2 "></i>AI insights</p>
                                <a href="/" className="d-md-none text-decoration-none small flex-0"><i className="ms-3 fa-light fa-arrow-right small"></i></a>
                            </div>
                            <p className="mb-0 text-white">Understanding all the breaking news and trending topics using the power of AI.</p>
                        </div>
                        <AIInsights data={data} />
                    </div>
                </>
            )}
        </>
    )
}