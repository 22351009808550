
import HelperFunctions from '../../helpers/HelperFunctions';
import PlayerTeamLozenge from './PlayerTeamLozenge';
import NewsImage from './NewsImage';
import { Link } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function NewsFeedNewsCard({ props, data = {}, onRemoveNews, newsCard = false }) {

    const [isBookmarked, setIsBookmarked] = useState(false);
    const [showMoreModal, setShowMoreModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const { text, source_name, source_url, news_date, media, recognised_players = [], recognised_team = [], saved } = { ...data };
    const { stringTruncate, durationFormatter } = HelperFunctions();

    useEffect(() => {
        setIsBookmarked(saved);
    }, [saved]);

    const handleSaveBookmark = () => {

        apiService.apiPostJson(`${process.env.REACT_APP_API_URL}/mynews/news/save`, { news_id: data.id })
            .then(() => {
                setIsBookmarked(true);
            })
            .catch(error => {
                console.log('Failed to save news. ' + error.message);
            });
    };

    const handleRemoveBookmark = () => {
        apiService
            .apiPostJson(`${process.env.REACT_APP_API_URL}/mynews/news/remove`, { news_id: data.id })
            .then(() => {
                onRemoveNews && onRemoveNews(data.id);
                setIsBookmarked(false);
            })
            .catch((error) => {
                console.error('Failed to remove news. ' + error.message);
            });
    };

    const handleOpenMoreModal = (items) => {
        setSelectedItems(items);
        setShowMoreModal(true);
    };
    const handleCloseMoreModal = () => setShowMoreModal(false);

    const renderLozenges = (items, type) => (
        <>
            {items.slice(0, 2).map((item, index) => (
                <Link
                    to={`/playerdetail/${item.uuid}`}
                    className="ms-2 mt-2 text-decoration-none"
                    key={`${type}-${index}`}
                >
                    <PlayerTeamLozenge data={item} player={true} />
                </Link>
            ))}
            {items.length > 2 && (
                <div className="ms-2 mt-2 text-white small align-self-center cursor-pointer" onClick={() => handleOpenMoreModal(items)}>
                    +{items.length - 2} more
                </div>
            )}
        </>
    );

    return (
        <div className="d-flex flex-row h-100 w-100 justify-content-between text-decoration-none" >
            <div className="d-flex flex-column h-100  w-100 justify-content-between"  >
                <div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="d-flex align-items-center justify-content-start me-2">
                            <img className="object-fit-contain disc-3 disc-md-4 rounded-half flex-none bg-white object-fit-cover" src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + source_url + '&size=64'} alt="icon" />
                            <p className="mb-0 ms-2 small">{source_name && <span>{source_name}&nbsp;&middot;&nbsp;</span>}{news_date && <span className='text-neutral-gray-700'>{durationFormatter(new Date(news_date).valueOf())}</span>}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            {!newsCard && <div className="d-flex align-items-center justify-content-end">
                                {isBookmarked ? (
                                    <p className='text-crayola-red-500 mb-0  cursor-pointer' title="Remove bookmark"
                                    onClick={handleRemoveBookmark}> Saved for later <i
                                        
                                        className="fa-solid fa-bookmark cursor-pointer ps-2"
                                        
                                    ></i></p>
                                ) : (
                                    <p className='mb-0  cursor-pointer' title="Save for later"
                                    onClick={handleSaveBookmark}> Save for later <i
                                        
                                        className="fa-light fa-bookmark cursor-pointer ps-2"
                                        
                                    ></i></p>
                                )}
                            </div>}

                            {newsCard && <>
                                <p 
                                    className='text-crayola-red-500 mb-0 cursor-pointer' title="Remove bookmark"
                                    onClick={handleRemoveBookmark}> Saved for later <i
                                   
                                    className="fa-solid fa-bookmark ps-2"                                    
                                ></i></p>
                                <a href={source_url} target='_blank' rel='noreferrer' className="bg-zero-dark-100 d-none text-white border-zero-dark-100 btn btn-outline-primary disc-badge position-absolute top-50 btn-md rounded-circle d-flex justify-content-center align-items-center show-on-hover-right-center text-decoration-none">
                                    <i className="fa-regular fa-arrow-up-right-from-square"></i>
                                </a></>
                            }
                        </div>
                    </div>
                    
                <a target='_blank' rel='noreferrer' href={source_url} className='text-decoration-none'>
                    {media && !media.includes('twitter-blue', 0) && !newsCard && <div className='w-100 mb-3' >
                        <NewsImage src={media} alt="News image" />
                    </div>}
                    <p className={`loose fw-normal text-neutral-gray-500 ${recognised_players || recognised_team ? 'mb-1' : 'mb-3'}`}>
                        {stringTruncate(text, newsCard ? 100 : 450)}
                    </p>

                </a>
                    
                </div>
                <div className="d-flex justify-content-end align-items-start flex-wrap">
                    {recognised_players.length > 0 && renderLozenges(recognised_players, 'player')}
                    {recognised_team.length > 0 && renderLozenges(recognised_team, 'team')}
                </div>
            </div>
            <Modal size="lg" centered show={showMoreModal} onHide={handleCloseMoreModal} >
                <Modal.Body className='bg-dark-mode-200 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-300 text-black disc-md-45 btn-close-dark mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseMoreModal} >
                    </button>
                    <div className="d-flex flex-wrap">
                        {selectedItems.map((item, index) => (
                            <Link
                                key={index}
                                className="ms-2 mt-2 text-decoration-none"
                                to={`/playerdetail/${item.uuid}`}
                            >
                                <PlayerTeamLozenge data={item} player={true} />
                            </Link>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
