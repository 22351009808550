import PlayerCard from '../templates/PlayerCard';
import { apiService } from '../services/apiService';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../templates/Loader';
import { authenticationService } from '../services/authenticationService';

export default function OnesToWatch({ props, onError }) {
    const [loadingData, setLoadingData] = useState(true);
    const [playerData, setPlayerData] = useState([]);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/players/ones-to-watch')
            .then((data) => {
                setPlayerData(data.data);
                setLoadingData(false);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }   
                })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="d-flex justify-content-between">
                <p className="large fw-semi-bold">Ones to watch</p>
                <Link to="/listing/ones-to-watch" className="text-decoration-none text-crayola-red-500 small flex-0 d-none d-md-inline">
                    More ones to watch<i className="ms-3 fa-light fa-arrow-right small"></i>
                </Link>
            </div>

            <div className="d-flex overflow-scroll overflow-md-visible d-grid-md grid-gap-3 grid-md-5-cols pb-2 pb-md-0 position-relative">
                {loadingData ? (
                    <Loader />
                ) : (
                    <>
                        {playerData.length > 0 ? (
                            playerData.slice(0,5).map((info, i) => (
                                <Link
                                to={`/playerdetail/${info.uuid}`}
                                    className="d-flex flex-column shadow-hover-sm justify-content-between flex-none flex-md-auto me-1half me-md-0 w-75 w-md-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none pt-2half pt-md-4 translate-icon-on-hover cursor-pointer"
                                    key={i}
                                >
                                    <PlayerCard aiinsights={false} data={info} />
                                </Link>
                            ))
                        ) : (
                            <p>No data available</p>
                        )}
                    </>
                )}
            </div>
            <div className="d-block d-md-none justify-content-center mt-2">
               <Link to="/listing/ones-to-watch" className="btn btn-md bg-crayola-red-500  w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold">
                    More ones to watch
                </Link>
            </div>
        </>
    );
}
