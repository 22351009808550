import React, { useEffect, useState, useCallback } from 'react';
import { authenticationService } from '../../services/authenticationService';
import { apiService } from '../../services/apiService';
import Pagination from '../../templates/Pagination';
import Loader from '../../templates/Loader';
import { Link } from 'react-router-dom';
import SavedSearchCard from '../../templates/NewsFeed/SavedSearchCard';

export default function SavedSearchTerms({ onError, inMegaMenu = false }) {


    const [savedSearches, setSavedSearches] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const [resultsTotal, setResultsTotal] = useState(0);
    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const [page, setPage] = useState(1);
    const [visibleCards, setVisibleCards] = useState(8);
    const columnCount = 3;
    const rowCount = 4;
    
    const paginationEventHandler = (page) => {
        setPage(parseInt(page, 10));
    };

    const pageSizeEventHandler = (page_size) => {
        setPageSize(page_size);
        setPage(1);
    };

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 8);
    };



    const loadSavedSearchTerms = useCallback(async () => {

        setLoadingData(true);

        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/searchterms/list')
            .then((resp) => {
                setLoadingData(false);
                setSavedSearches(resp.data.sort((a, b) => a.name.localeCompare(b.name)));
                setResultsTotal(resp.data.length);
                setPagesTotal(Math.ceil(resp.data.length / pageSize));
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                         //todo add error handling
                        //onError(error.message + ' error');
                    }
                }
            );
    }, [ pageSize]);


    useEffect(() => {
        loadSavedSearchTerms();

    }, [loadSavedSearchTerms]);



    const currentPageResults = savedSearches.slice((page - 1) * pageSize, page * pageSize);
    const mobileViewResults = savedSearches.slice(0, visibleCards);

    return (
        <>
            {!inMegaMenu ? <>
                {loadingData ? (
                    <div className="ratio ratio-21x9 column-span-3">
                        <div className='center-content'>
                            <Loader />
                        </div>
                    </div>
                ) : (
                    <>
                        {savedSearches.length > 0 ? (
                            <>

                                <div className='mb-3 d-md-block d-none'>
                                    <div className="d-grid grid-gap-3 grid-md-3-cols position-relative">
                                        {currentPageResults.map((value, index) => (
                                            <SavedSearchCard key={index} data={value} inMegaMenu={inMegaMenu} />

                                        ))}
                                    </div>
                                </div>
                                <div className='mb-3 d-md-none d-block'>
                                    <div className="d-grid-md grid-gap-3 grid-md-2-cols position-relative">
                                        {mobileViewResults.map((value, index) => (
                                            <div className='mb-25' key={index}>
                                                <SavedSearchCard data={value} />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {1 < pagesTotal && visibleCards < resultsTotal && (
                                    <button onClick={loadMoreHandler} className="btn btn-md bg-crayola-red-500 d-md-none d-block w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold mt-3">
                                        Show more
                                    </button>
                                )}
                                {1 < pagesTotal &&
                                    <div className='d-md-flex d-none justify-content-center'>
                                        <Pagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize} />
                                    </div>
                                }
                            </>


                        ) : (
                            <>No searches have been saved. Searches can be saved in the <Link to="/newsfeed" className="">news and insights</Link> area.</>
                        )}


                    </>
                )}
            </>
                : <div className={`d-grid grid-gap-3 grid-md-3-cols fixed-1-and-${Math.min(4,Math.ceil(savedSearches.length / columnCount) + 2)}-row-3`}>
                    <p className='text-crayola-red-400 column-span-3 grid-gap-3'>Saved searches</p> {loadingData ? (
                        <div className="ratio ratio-21x9 column-span-3">
                            <div className='center-content'>
                                <Loader />
                            </div>
                        </div>
                    ) : (
                        <>
                            {savedSearches.length > 0 ? (

                                <>
                                    {currentPageResults.map((value, index) => (
                                        <SavedSearchCard key={`saved-search-${index}`} data={value} inMegaMenu={true} />

                                    ))}                                  
                                    <div className="column-span-3 d-flex justify-content-end align-items-start">
                                        <Link className="small" to="/saved/searches"><span className=" like-link-underline">View all</span><i className="ms-2 fa-light fa-arrow-up-right small "></i></Link>
                                    </div>
                                    {[...Array(Math.max(0,rowCount - (Math.floor(savedSearches.length / columnCount) + 2)))].map((x, i) =>
                                        <div />
                                    )}
                                </>

                            ) : (
                                <div className="column-span-3">No searches have been saved. Searches can be saved in the <Link to="/newsfeed" className="">news and insights</Link> area.</div>
                            )}


                        </>
                    )}</div>
            }



        </>

    )
}