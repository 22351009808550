import HelperFunctions from '../helpers/HelperFunctions';

export default function InsightCard({ playerInsights, insights, textLimit = true }) {

    const { stringTruncate } = HelperFunctions();
    const displayText = textLimit ? stringTruncate(insights) : stringTruncate(insights,180);


    return (
        <div
            className={`cursor-pointer h-100 mb-3 mb-md-0 ${playerInsights ? 'bg-crayola-red-500 align-items-center justify-content-between px-md-3 py-md-3 py-2 px-2half' : 'bg-dark-mode-300 flex-column p-md-4 p-3 rounded-3'} rounded-2 position-relative text-decoration-none w-100 translate-icon-on-hover d-flex`}>
            {!playerInsights && <i className='fa-regular fa-sparkles mb-4 text-crayola-red-600'></i>}
            <div className="d-flex align-items-center">
                {playerInsights && <i className='fa-regular fa-sparkles me-4 text-black'></i>}
                <p className="mb-0 d-none d-md-block">
                    {displayText ||
                        'Pep Guardiola faces full 12-month campaign with Manchester City, while Matz Sels battles for No. 1 spot at Nottingham Forest.'}
                </p>
                <p className="mb-0 extra-small d-block d-md-none">
                    {displayText ||
                        'Pep Guardiola faces full 12-month campaign with Manchester City, while Matz Sels battles for No. 1 spot at Nottingham Forest.'}
                </p>
            </div>
            {playerInsights && <div className="ms-4 d-flex flex-column justify-content-end">
                <i className='icon text-black fa-solid fa-arrow-right small'></i>
            </div>}
        </div>
    );
}
