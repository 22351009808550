import React, { useEffect, useState } from 'react';
import PlayerCardResults from '../templates/PlayerCardResults';
import { apiService } from '../services/apiService';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../templates/Pagination';
import { Modal, Spinner } from "react-bootstrap";

const ScoutAssistBasicSearch = ({ onError }) => {


    const { example } = useParams();

    const [loadingData, setLoadingData] = useState(true);
    const [results, setResults] = useState([]);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    // const [orderBy, setOrderBy] = useState('Position');
    const [visibleCards, setVisibleCards] = useState(10);


    const pageSizeEventHandler = (page_size) => {
        setPageSize(page_size);
        setPage(1);
    };

    const paginationEventHandler = (page) => {
        setPage(parseInt(page, 10));
    };

    const orderByEventHandler = (event) => {
        setPage(1);
        // setOrderBy(event.target.value);
    };

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + `/scout_assist/example${example}`)
            .then((data) => {
                setInterval(() => {
                    setResults(data);
                    setResultsTotal(data.length);
                    setPagesTotal(Math.ceil(data.length / pageSize));
                    setLoadingData(false);
                }, 750);


            })
            .catch((error) => {
                onError(error.message + ' error');
                setLoadingData(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);

    useEffect(() => {
        setPagesTotal(Math.ceil(resultsTotal / pageSize));
    }, [resultsTotal, pageSize]);

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 10);
    };

    const currentPageResults = results.slice((page - 1) * pageSize, page * pageSize);
    const mobileViewResults = results.slice(0, visibleCards);


    return (
        <div className='h-100'>
            {loadingData ? (
                <Modal size="sm" centered show={true} >
                    <Modal.Body className=' bg-dark-mode-300 rounded-2 p-4 d-flex  flex-column align-items-center'>
                        <Spinner animation="border" size="lg" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <h5 className='my-4'>Finding your matches</h5>
                        <div className='p-4 mb-0 bg-dark-mode-800  rounded-2'>
                            {('1' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Strikers</strong> with <strong className=" text-neutral-gray-500">low injury metrics</strong> who have scored at least a <strong className=" text-neutral-gray-500">goal per game</strong> this season.</p>}
                            {('2' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Defenders</strong> with the <strong className=" text-neutral-gray-500"> most clean sheets</strong> providing <strong className=" text-neutral-gray-500">solid backline support.</strong>.</p>}
                            {('3' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Midfielders</strong> with <strong className=" text-neutral-gray-500">exceptional passing accuracy</strong> who <strong className=" text-neutral-gray-500">control the game's tempo.</strong></p>}
                            {('4' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Goalkeepers</strong> with the <strong className=" text-neutral-gray-500">highest save percentage</strong> demonstrating outstanding reflexes.</p>}
                        </div>
                    </Modal.Body>
                </Modal>
            ) : (
                <>
                    <div className='max-w-f-650 border border-1 mb-5 border-neutral-gray-900 bg-dark-dark-mode-50 rounded-pill p-3 d-flex justify-content-between align-items-center'>
                        <div>
                            <div className='bg-crayola-red-400 disc-6 rounded-circle center-content me-3'><i className="fa-regular fa-sparkles text-black"></i></div>
                        </div>
                        {('1' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Strikers</strong> with <strong className=" text-neutral-gray-500">low injury metrics</strong> who have scored at least a <strong className=" text-neutral-gray-500">goal per game</strong> this season.</p>}
                        {('2' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Defenders</strong> with the <strong className=" text-neutral-gray-500"> most clean sheets</strong> providing <strong className=" text-neutral-gray-500">solid backline support.</strong>.</p>}
                        {('3' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Midfielders</strong> with <strong className=" text-neutral-gray-500">exceptional passing accuracy</strong> who <strong className=" text-neutral-gray-500">control the game's tempo.</strong></p>}
                        {('4' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Goalkeepers</strong> with the <strong className=" text-neutral-gray-500">highest save percentage</strong> demonstrating outstanding reflexes.</p>}
                        <i className="icon text-crayola-red-400 fa-solid fa-arrow-right small me-3"></i>
                    </div>
                    {results.length > 0 ? (
                        <>
                            <div className='d-md-flex d-none justify-content-between align-items-center mb-5'>
                                <p className='mb-0'>
                                    Showing {((page - 1) * pageSize) + 1}-{Math.min(page * pageSize, resultsTotal)} of {resultsTotal} result{(resultsTotal === 1) ? '' : 's'}
                                </p>
                                {
                                    false && <div className='d-flex align-items-center max-w-px-md-140 w-100'>
                                        <select className="form-select form-select-md bg-neutral-gray-900 border-0 " onChange={orderByEventHandler}>
                                            <option value={'position'}>Position</option>
                                            <option value={'nationality'}>Nationality</option>
                                            <option value={'age'}>Age</option>
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className='bg-dark-mode-700 p-md-4 p-2half rounded-2 mb-4 d-md-block d-none'>
                                <div className="d-grid-md grid-gap-4 grid-md-4-cols position-relative">
                                    {currentPageResults.map((value, index) => (
                                        <Link key={index} to={`/playerdetail/${value.uuid}`} className={'mb-2 mb-md-0 cursor-pointer d-flex flex-column align-items-center justify-content-between h-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none pt-2half pt-md-4 w-100 translate-icon-on-hover'}>
                                            <PlayerCardResults data={value} />
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            {1 < pagesTotal &&
                                <div className='d-md-block d-none'>
                                    <Pagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize} />
                                </div>
                            }

                            <div className="d-md-none d-block">
                                <div className='d-grid grid-gap-2half grid-2-cols position-relative'>

                                    {mobileViewResults.map((value, index) => (
                                        <Link key={index} to={`/playerdetail/${value.uuid}`} className={'cursor-pointer d-flex flex-column align-items-center justify-content-between h-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none w-100 translate-icon-on-hover'}>
                                            <PlayerCardResults data={value} />
                                        </Link>
                                    ))}
                                </div>
                                {1 < pagesTotal && visibleCards < resultsTotal && (
                                    <button onClick={loadMoreHandler} className="btn btn-md bg-crayola-red-500  w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold mt-3">
                                        Show more
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (
                        <>No results</>
                    )}
                </>
            )}
        </div>
    );
};

export default ScoutAssistBasicSearch;
