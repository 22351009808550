import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function ScoutAssist({ props }) {

    const navigate = useNavigate();

    const [question, setQuestion] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        navigate({
            pathname: '/scoutassist',
            search: `?question=${question}`,
        });

    }

    return (
        <div>
            <div className="column-span-2">
                <div className="d-flex justify-content-between">
                    <p className="mb-2 large fw-semi-bold">Scout assist</p>
                    <Link to="/perfectplayer" className="text-decoration-none text-crayola-red-500 d-none d-md-block small flex-0 fw-normal">Scout your perfect player<i className="ms-3 fa-light fa-arrow-right small"></i></Link>
                </div>
                <p className="mb-3">Helping you find the players that matter most to you and your team.</p>
            </div>
            <div className="d-flex overflow-scroll overflow-md-visible d-grid-md  grid-gap-3 grid-md-4-cols  pb-2 pb-md-0 position-relative" >
                <Link to={'/scoutassist_basic/1'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                    <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Strikers</strong> with <strong className=" text-neutral-gray-500">low injury metrics</strong> who have scored at least a <strong className=" text-neutral-gray-500">goal per game</strong> this season.
                        </p>
                    <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                </Link>
                <Link to={'/scoutassist_basic/2'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                    <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Defenders</strong> with the <strong className=" text-neutral-gray-500"> most clean sheets</strong> providing <strong className=" text-neutral-gray-500">solid backline support.</strong></p>
                    <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                </Link>
                <Link to={'/scoutassist_basic/3'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                    <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Midfielders</strong> with <strong className=" text-neutral-gray-500">exceptional passing accuracy</strong> who <strong className=" text-neutral-gray-500">control the game's tempo.</strong></p>
                    <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                </Link>
                <Link to={'/scoutassist_basic/4'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                    <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Goalkeepers</strong> with the <strong className=" text-neutral-gray-500">highest save percentage</strong> demonstrating outstanding reflexes.</p>
                    <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                </Link>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="btn-group focus-dark active w-100 mt-5">
                    <div className="btn btn-link border-1 py-3 pe-2 border-neutral-gray-900 rounded-pill rounded-top-end-0 border-end-0 rounded-bottom-end-0 bg-dark-mode-50  small position-relative center-content"><div className='bg-crayola-red-500 rounded-circle badge mb-0'><i className="fa-regular  fa-sparkles "></i></div></div>
                    <input type="text"  className="py-25 px-2 text-white bg-dark-mode-50 border-1 border-start-0 border-end-0 border-neutral-gray-900 rounded-pill form-control form-control-lg rounded-top-start-0 ro rounded-top-end-0 rounded-bottom-start-0 rounded-bottom-end-0" placeholder="Find your perfect player..."  required value={question} onChange={(e) => setQuestion(e.target.value)}  />
                    <button type="submit" className="btn btn-link border-1 border-neutral-gray-900 rounded-pill rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"><i className="fa fa-arrow-right text-crayola-red-400"></i></button>
                </div>
            </form>
           
            
        </div>
    );
}
