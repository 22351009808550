import { useEffect, useState } from 'react';

import FeaturedNewsCard from '../templates/FeaturedNewsCard';
import { apiService } from '../services/apiService';
import Loader from '../templates/Loader';
import { authenticationService } from '../services/authenticationService';

export default function FeaturedNewsItem({ props, onError }) {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/news/list')
            .then((resp) => {
                setData(resp.data);
                setLoadingData(false);
            },
            error => {
                if ('401' === error.message) {
                    authenticationService.logout();
                } else {
                    onError(error.message + ' error');
                }   
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
        {loadingData ? (
            <Loader />
        ) : (
            <>
            { 0 < data.length && 
                <div className="mb-4 mb-md-0">
                    <FeaturedNewsCard data={data[0]}/>
                </div> 
            }
            </>
        )}
        </>
    )
}