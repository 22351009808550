import { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import SavedSearchTerms from '../NewsFeed/SavedSearchTerms';
import SavedDataMenu from '../Menus/SavedDataMenu';
import Watchlist from '../NewsFeed/Watchlist';
import HelperFunctions from '../../helpers/HelperFunctions';

export default function SavedData({ props, data }) {

    const location = useLocation();

    const ref = useRef(null);

    const savedPaths = ['/saved/watchlist', '/saved/news', '/saved/searches'];

    const [showMenu, setShowMenu] = useState(false);
    const [modalStyle, setStyle] = useState({});


    const mouseEnterHandler = () => {
        const { addClassToBody, getBodyScrollbarWidth } = HelperFunctions();
        const scrollbarWidth = getBodyScrollbarWidth(ref.current.ownerDocument);
        // need the following to stop the underlying 
        // page from shifting when the scollbar is removed
        if (scrollbarWidth > 0) {
            ref.current.ownerDocument.body.style.paddingRight = `${scrollbarWidth}px`;
            setStyle({
                marginLeft: `-${scrollbarWidth}px`
            });
        }

        addClassToBody(ref.current, 'overflow-hidden');
        setShowMenu(true);
    }

    const mouseLeaveHandler = useCallback(() => {
        const { removeClassFromBody } = HelperFunctions();
        ref.current.ownerDocument.body.style.paddingRight = `0px`;
        setStyle({});
        removeClassFromBody(ref.current, 'overflow-hidden');
        setShowMenu(false);
    }, []);

    useEffect(() => {
        const { removeClassFromBody } = HelperFunctions();
        ref.current.ownerDocument.body.style.paddingRight = `0px`;
        setStyle({});
        removeClassFromBody(ref.current, 'overflow-hidden');
        setShowMenu(false);
    }, [location]);

    /*
 
    */
    return (
        <div className='position-relative' ref={ref}>
            <div className=''
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
            >
                <NavLink
                    className={`nav-link fw-normal border-body-text dropdown-toggle ${savedPaths.includes(location.pathname) ? 'active' : ''}`}
                    to="/saved/watchlist"
                >
                    My saved data
                </NavLink>
                {showMenu &&
                    <>
                        <div className={`fade top-auto modal-backdrop show`}></div>
                        <div className='position-fixed start-0  vw-100  z-index-modal'>
                            <div className='container position-relative z-index-modal'>

                                <div className='rounded-3 bg-dark-mode-800  '
                                    style={modalStyle}
                                    onMouseEnter={mouseEnterHandler}
                                    onMouseLeave={mouseLeaveHandler}>
                                    <div className='p-3'>
                                        <div className="pt-4">
                                            <p className='mb-2 fw-semi-bold'>My saved data</p>
                                            <p className='text-neutral-gray-700'>Access all your saved information including players and searches</p>
                                        </div>
                                        <div className="d-flex pt-2">
                                            <div className="d-none d-md-block flex-grow-0 w-px-262 min-w-px-262 pe-2">
                                                <p className='text-crayola-red-400'>All areas</p>
                                                <div className=' pe-2  '>
                                                    <SavedDataMenu />
                                                </div>
                                            </div>
                                            <div className=" flex-grow-1">
                                                <div className="d-grid-md grid-2-cols grid-gap-3 mb-4">
                                                    <SavedSearchTerms inMegaMenu={true} />
                                                    <Watchlist inMegaMenu={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    )
}