import { useEffect, useState } from 'react';
import NewsCard from '../templates/NewsCard';
import { apiService } from '../services/apiService';
import Loader from '../templates/Loader';
import { authenticationService } from '../services/authenticationService';
import { Link } from 'react-router-dom';

export default function NewsGrid({ props, onError }) {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/news/list')
            .then((resp) => {
                setData(resp.data);
                setLoadingData(false);
            },
            error => {
                if ('401' === error.message) {
                    authenticationService.logout();
                } else {
                    onError(error.message + ' error');
                }   
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {loadingData ? (
                <Loader />
            ) : (
                <>
                    { 0 < data.length && 
                        <>
                            
                            <div className="d-flex overflow-scroll fixed-1-row-bigger overflow-md-visible d-grid-md grid-gap-3 grid-md-2-cols pb-2 pb-md-0 position-relative">
                                <div className='column-span-2 d-none d-md-flex justify-content-end mb-7'>
                                    <Link to="/newsfeed"  className="text-decoration-none cursor-pointer text-crayola-red-500 small fw-normal">Search for news and insights<i className="ms-3 fa-light fa-arrow-right small"></i></Link>
                                </div>
                                {
                                    data.slice(1,7).map(function (object, i) {
                                        return (
                                            <div className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 w-md-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none p-3" key={i}>
                                                <NewsCard data={object} />
                                            </div>
                                        );
                                    })
                                }
                                { false && <div className="btn d-none d-md-flex justify-content-center column-span-2 bg-crayola-red-500  fw-semi-bold rounded-2 position-relative text-decoration-none py-2 small">More from the news <i className="ms-2 fa-solid fa-arrow-right small"></i></div>}
                            </div>
                           { false && <div className="btn d-flex mt-2 d-md-none justify-content-center column-span-2 bg-crayola-red-500  fw-semi-bold rounded-2 position-relative text-decoration-none py-2 small">More from the news <i className="ms-2 fa-solid fa-arrow-right small"></i></div>}
                        </>
                    }
                </>
            )}
        </>
    )
}