import React, { useEffect, useState } from 'react'
import { apiService } from '../../services/apiService';
import { authenticationService } from '../../services/authenticationService';
import { Link } from 'react-router-dom';
import PlayerTeamLozengeLarge from '../../templates/NewsFeed/PlayerTeamLozengeLarge';
import Loader from '../../templates/Loader';
const Watchlist = ({ onError }) => {

    const [loadingData, setLoadingData] = useState(true);
    const [playerData, setPlayerData] = useState([]);
    const pageSize = 6;
    const page = 1;
    const columnCount = 2;
    const rowCount = 5;

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/watchlist')
            .then((data) => {
                const players = Array.isArray(data) ? data : [];
                setPlayerData(players);

                setLoadingData(false);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        //todo add error handling
                        //onError(error.message + ' error');
                    }
                })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);



    const currentPageResults = playerData.slice((page - 1) * pageSize, page * pageSize);


    return (
        <div className={`d-grid grid-gap-2 grid-md-2-cols fixed-1-and-${Math.ceil(currentPageResults.length / columnCount) + 2}-row-4`}>
            <p className='text-crayola-red-400 column-span-2 grid-gap-3'>Watched players</p>
            {
                loadingData ? (
                    <div className="ratio ratio-21x9 column-span-2">
                        <div className='center-content'>
                            <Loader />
                        </div>
                    </div>
                ) : (
                    <>
                        {playerData.length > 0 ? (

                            <>
                                {currentPageResults.map(function (info, index) {
                                    return (
                                        <Link to={`/playerdetail/${info.uuid}`} className='text-decoration-none w-100 h-100 ' key={`watchlist-${index}`}>
                                            <PlayerTeamLozengeLarge data={info}  />
                                        </Link>
                                    )

                                })}                              
                                <div className="column-span-2 d-flex justify-content-end align-items-start">
                                    <Link className="small" to="/saved/watchlist"><span className=" like-link-underline">View all</span><i className="ms-2 fa-light fa-arrow-up-right small "></i></Link>
                                </div>
                                {[...Array(rowCount - (Math.floor(currentPageResults.length / columnCount) + 2))].map((x, i) =>
                                    <div />
                                )}

                            </>

                        ) : (
                            <div className="column-span-2">No players have been added to your watchlist. To follow a player, please use the search in the <Link to="/saved/watchlist" className="">manage your watchlist</Link> page to add a player.</div>
                        )}

                    </>
                )
            }
        </div>
    )
}

export default Watchlist