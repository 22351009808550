import React from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import {TeamSVG, PlayerSVG} from './SvgIcons';

export default function PlayerCard({ data = {}, aiinsights = false, includeteam = true, watchlist = false, stats = [] }) {
    const { first_name, last_name, date_of_birth, image, positions = [] } = data;
    const fullName = `${first_name || ''} ${last_name || ''}`.trim();

    const { ageFormatter, statsFormatter ,firstNameExtracted } = HelperFunctions();

    const age = date_of_birth ? ageFormatter(date_of_birth) : '';

    const team_image = data.team?.image || data.team_image;
    const team_name = data.team?.name || data.team || data.team_name;

    if (data.position_name)
        positions.push({ 'name': data.position_name });


    return (
        <>
            <div className={`h-100 d-flex flex-column justify-content-between align-items-center ${watchlist?'px-3':'px-2half'} px-md-3 w-100`}>
                <div className={`d-flex flex-row flex-md-column align-items-md-center w-100 mb-2 mb-md-0`}>
                    {image ?
                        <img className={`object-fit-cover disc-65 disc-md-9 rounded-circle bg-white mb-0 mb-md-3 me-md-0 me-2`} src={image} alt={fullName} />
                        :
                        <PlayerSVG className='disc-65 disc-md-9 mb-0 mb-md-3 me-2 me-md-0' />
                    }

                    <div className='d-flex flex-column align-items-start align-items-md-center w-100'>
                        <p className="mb-0 large fw-normal text-center">
                            {firstNameExtracted(first_name)} <strong className="fw-semi-bold">{last_name}</strong>
                        </p>
                        {watchlist &&
                            <div className='d-flex justify-content-between align-items-center w-100 py-2'>
                                <div className='center-content rounded-2 rounded-top-start-0 rounded-top-end-0'>
                                    {team_image ?
                                        <img className="object-fit-cover disc-35 disc-md-4 rounded-circle me-2" src={team_image} alt={team_name || "Team"} />
                                        :
                                        <TeamSVG />
                                    }
                                    <p className="mb-0 small">{team_name || ""}</p>
                                </div>
                                <p className="mb-0 extra-small me-">JWD</p>
                            </div>}
                        {!watchlist && <p className="mb-0 mb-md-3 small text-neutral-gray-700 text-md-center">{age}{0 < age && 0 < positions.length ? <>&nbsp;&middot;&nbsp;</> : <></>}{positions.map(position => position.name).join(' / ')}</p>}
                        {
                            0 < stats.length &&
                            <div className='mb-3 px-6 px-md-3 d-flex flex-column align-items-start align-items-md-center  text-crayola-red-600 '>
                                {stats.map((value, index) => (
                                    <>
                                        <p className='d-none d-md-block mb-0 small'>{Math.floor(value[1]).toLocaleString()} {statsFormatter(value[0])}</p>
                                        <p className='d-block d-md-none mb-0 extra-small'>{Math.floor(value[1])} {value[0]}</p>
                                    </>

                                ))
                                }
                            </div>
                        }
                    </div>
                </div>
                {aiinsights && (
                    <div className='d-flex align-items-start align-items-md-center text-crayola-red-600 mb-2 mb-md-3 w-100 w-md-auto px-6 px-md-3'>
                        <div className='d-none d-md-block'>
                            <i className='fa fa-sparkles me-3'></i>
                        </div>
                        <div className='d-block d-md-none'>
                            <i className='fa fa-sparkles me-3 extra-small'></i>
                        </div>
                        <p className='d-none d-md-block mb-0 small'>First cap for national team at 18 years old</p>
                        <p className='d-block d-md-none mb-0 extra-small'>First cap for national team at 18 years old</p>
                    </div>
                )}
            </div>
            {includeteam && (
                <div className='bg-dark-mode-50 w-100 py-2 px-2half px-md-3 center-content rounded-2 rounded-top-start-0 rounded-top-end-0'>
                    {team_image ?
                        <img className="object-fit-cover disc-35 disc-md-4 rounded-circle me-2" src={team_image} alt={team_name || "Team"} />
                        :
                        <TeamSVG />
                    }
                    <p className="mb-0 small text-neutral-gray-700">{team_name || ""}</p>
                </div>
            )}
        </>
    );
}
