import React, { useEffect, useState } from "react";


function Pagination(props) {

    const [items, setItems] = useState([]);

    useEffect(() => {
        // calculate total pages
        //const totalPages = Math.ceil(props.pages_total / pageSize);
        //debugger;
        let startPage, endPage;
        if (props.pages_total <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = props.pages_total;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (props.page <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (props.page + 4 >= props.pages_total) {
                startPage = props.pages_total - 9;
                endPage = props.pages_total;
            } else {
                startPage = props.page - 5;
                endPage = props.page + 4;
            }
        }

        // create an array of pages to ng-repeat in the pager control
        let pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        let items = [];

        pages.forEach((number, i) => {
            items.push(
                <div key={number}
                    onClick={e => {

                        if (props.onItemClick)
                            props.onItemClick(e.target.innerText);
                    }}
                    className={'disc-6 rounded-2  center-content cursor-pointer m-1 ' + (number === props.page ? 'bg-crayola-red-500 text-white' : 'bg-neutral-gray-900 text-white')}>{number}</div>,
            );
        });

        setItems(items);

    }, [props]);


    return (
        <>
            {0 < props.pages_total ?
                <div className="d-flex justify-content-center">
                    <div className="d-flex align-items-center">
                        <div
                            onClick={e => {
                                if (props.onItemClick)
                                    props.onItemClick(props.page - 1);
                            }}
                            className={'btn btn-link me-1 px-3 py-2 ' + (1 > props.page - 1 ? 'disabled' : '')}>
                            <i className="fa-light fa-angle-left me-2"></i>Previous
                        </div>

                        {0 < props.pages_total &&
                            <>
                                {props.page > 1 && props.pages_total > 1 && <div className="me-2">&middot;&middot;&middot;</div>}
                                {items}
                                {props.page < props.pages_total && props.pages_total > 1 && <div className="ms-2">&middot;&middot;&middot;</div>}
                            </>
                        }

                        <div
                            onClick={e => {
                                if (props.onItemClick)
                                    props.onItemClick(props.page + 1);
                            }}
                            className={'btn btn-link ms-1 px-3 py-2 ' + (props.pages_total < props.page + 1 ? 'disabled' : '')}>
                            Next<i className="fa-light fa-angle-right ms-2"></i>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
        </>
    )

};


export default Pagination
